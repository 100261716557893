<template>
  <v-select
          label="Typ přístupu"
          color="light-green darken-1"
          class="col-3 sm6"
          :value="value"
          :items='accessTypes'
          @change="onChange"
  ></v-select>
</template>


<script>
    export default {
        name: 'AccessSelect',

        props: {
            value: String,
        },

        data() {
          return {
            accessTypes: [
              {text: 'Neomezený přístup', value: '0'},
              {text: 'Přístup pouze s odpovědnou osobou', value: '1'},
              {text: 'Přístup pouze s kartou', value: '2'},
            ],
          }
        },

        methods: {
            onChange(value) {
                this.$emit('input', value);
            }
        },
    }
</script>

<style scoped>
</style>
