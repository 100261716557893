<template>
    <ItemNotFoundState v-if="objectNotFound" :objectName="'Místo'">
        <template #backButton>
            <BackButton :target="{name: 'map', params: {'tab': 'places'}}">Místa</BackButton>
        </template>
    </ItemNotFoundState>
    <Loading v-else-if="didLoadedPlace !== true || !singleDetail"></Loading>
    <div v-else>

        <div id="header">
            <BackButton :target="{name: 'map', params: {'tab': 'places'}}" >Místa</BackButton>
            <ModuleHeader class="pr-3">
                <div id="header-title">
                    <h1 v-if="!newPlace">Správa místa</h1>
                    <h1 v-else>Přidání místa</h1>
                    <v-alert v-if="isFormInvalid" type="warning" max-width="600px" class="mt-4 mb-0">Pro přidání místa je potřebný typ a pozice místa</v-alert>
                </div>

                <div id="header-actions">
                    <v-btn v-if="isUpdating" color="dark" @click="attemptSaveChanges" class="ml-5 mb-3 pull-right">Ukládání...</v-btn>
                    <v-btn v-else-if="hasRecentlyUpdated" outlined color="dark" @click="saveChanges" class="ml-5 mb-3 pull-right"><v-icon left>check</v-icon> Uloženo</v-btn>
                    <v-btn v-else color="primary" @click="attemptSaveChanges" class="ml-5 mb-3 pull-right" :disabled="isFormInvalid">Uložit</v-btn>
                </div>
            </ModuleHeader>
            <div class="language-selector">

              <div class="d-flex align-center">
                <v-tabs
                    v-model="currentLanguage"
                    icons-and-text
                    color="light-green darken-1 pull-right"
                >
                    <v-tab :href="'#' + this.languages[0].value" class="small" :key="languages[0].value">
                        <div class="d-flex align-center">
                            <country-flag country="cz"/>
                            <p class="mb-0 ml-2">{{this.languages[0].text}}</p>
                        </div>

                    </v-tab>

                    <v-tab :href="'#' + languages[1].value" class="small" :key="languages[1].value">
                        <div class="d-flex align-center">
                        <country-flag country='gb'/>
                        <p class="mb-0 ml-2">{{this.languages[1].text}}</p>
                        </div>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentLanguage">
                    <v-tab-item
                    :value="languages[0].value"
                    ></v-tab-item>
                    <v-tab-item
                    :value="languages[1].value"
                    ></v-tab-item>
                </v-tabs-items>

                </div>
            </div>
        </div>

        <div id="detailContent">
            <v-layout row class="detail-row mt-1">

                <v-col cols="7" id="form">
                    <v-form @submit.prevent="saveChanges" ref="form">

                        <div id="basic-info">
                            <h4 class="mb-3 title">
                            Základní informace
                            </h4>
                            <v-row>
                                <v-col cols="7">
                                    <v-text-field
                                            v-model.trim="singleDetail.localizedAttributes[currentLanguage].name"
                                            class="font-weight-bold"
                                            label="Název"
                                            color="light-green darken-1"
                                            placeholder="Místo bez názvu"
                                            @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                        v-model.trim="singleDetail.localizedAttributes[currentLanguage].nickname"
                                        label="Přezdívka"
                                        color="light-green darken-1"
                                        @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <v-select
                                            color="light-green darken-1"
                                            :items="buildingList"
                                            v-model="buildingType"
                                            label="Budova"
                                            @change="madeUnsavedChanges()"
                                    ></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select
                                            color="light-green darken-1"
                                            :items="sortedPlaceTypes"
                                            v-model="singleDetail.type"
                                            label="Typ"
                                            @change="madeUnsavedChanges()"
                                    ></v-select>
                                </v-col>
                                <v-col cols="3">
                                    <v-select
                                            v-model="singleDetail.floorId"
                                            :items="floorList"
                                            label="Podlaží"
                                            color="light-green darken-1"
                                            :disabled="disabledFloor"
                                            @change="madeUnsavedChanges()"
                                    ></v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                            v-model.number="singleDetail.code"
                                            type="number"
                                            label="Číslo oddělení"
                                            color="light-green darken-1"
                                            @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                    color="light-green darken-1"
                                    v-model.trim="singleDetail.localizedAttributes[currentLanguage].description"
                                    label="Popis"
                                    @change="madeUnsavedChanges()"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row row>
                                <AccessSelect
                                   v-model="singleDetail.accessType"
                                   @input="madeUnsavedChanges()"
                                />
                                <v-text-field
                                        color="light-green darken-1"
                                        v-model.number="singleDetail.numberOfSeats"
                                        type="number"
                                        label="Počet míst"
                                        class="col-3 sm6"
                                        @change="madeUnsavedChanges()"
                                ></v-text-field>
                                <v-switch
                                        v-model="singleDetail.computerWithProjector"
                                        class="mx-2" label="Projektor"
                                        color="light-green darken-1"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        @change="madeUnsavedChanges()"
                                ></v-switch>
                                <v-switch
                                        v-model="singleDetail.whiteboard"
                                        class="mx-2" label="Bílá tabule"
                                        color="light-green darken-1"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        @change="madeUnsavedChanges()"
                                ></v-switch>
                            </v-row>
                        </div>

                        <div id="authorities">
                            <h5 class="mt-10 mb-3">
                                Správce
                            </h5>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        color="light-green darken-1"
                                        v-model.number="singleDetail.person1Id"
                                        type="number"
                                        label="ID Osoby 1"
                                        prepend-icon="person"
                                        @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                            color="light-green darken-1"
                                            v-model.number="singleDetail.person2Id"
                                            type="number"
                                            label="ID Osoby 2"
                                            prepend-icon="person"
                                            @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        color="light-green darken-1"
                                        v-model.trim="singleDetail.phone"
                                        label="Telefonní číslo"
                                        prepend-icon="phone"
                                        @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                            color="light-green darken-1"
                                            v-model.trim="singleDetail.email"
                                            label="Kontaktní e-mail"
                                            prepend-icon="mail"
                                            @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="11">
                                    <v-text-field
                                            color="light-green darken-1"
                                            v-model.trim="singleDetail.url"
                                            label="Webové stránky"
                                            prepend-icon="web"
                                            @change="madeUnsavedChanges()"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div id="opening-hours">
                            <h5 class="mt-10 mb-3">
                                Otevírací doba
                            </h5>

                            <!-- checkbox na vypnutie/zapnutie oteviraci doby -->
                            <div>
                                <v-row>
                                    <v-col>
                                    <v-checkbox v-model="openHoursAreDisplayed" class="mx-2 ml-0" color="light-green darken-1" label="Nastavit otevírací dobu" @change="madeUnsavedChanges()"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="openHoursAreDisplayed" id="openhours">
                                <v-row
                                    v-for="(day, index) in getDays"
                                    :key="day">

                                    <!-- Checkbox na zavreno-->
                                    <v-col cols="1" class="pa-0 ml-5" style="height:60px">
                                        <v-checkbox v-model="disabledHoursOnCertainDay[index]" class="mt-3 ml-5" color="light-green darken-1" @change="madeUnsavedChanges()"></v-checkbox>
                                    </v-col>

                                    <v-col cols="1" class="pt-5"><b>{{day}}</b></v-col>

                                    <div style="display: contents">
                                        <v-col cols="3" class="pa-0 ml-3" style="height:60px">
                                            <v-menu
                                                    ref="menuOd"
                                                    v-model="menu2[index]"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="getOpenHoursForDay(index).openHoursTimes[0].openTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            v-model="getOpenHoursForDay(index).openHoursTimes[0].openTime"
                                                            label="Od"
                                                            prepend-icon="access_time"
                                                            readonly
                                                            v-on="on"
                                                            color="light-green darken-1"
                                                            :disabled="!disabledHoursOnCertainDay[index]"
                                                            @change="madeUnsavedChanges()"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                        v-if="menu2"
                                                        v-model="getOpenHoursForDay(index).openHoursTimes[0].openTime"
                                                        format="24hr"
                                                        color="light-green darken-1"
                                                        full-width
                                                        @click:minute="$refs.menuOd[index].save(getOpenHoursForDay(index).openHoursTimes[0].openTime)"
                                                        min="6:00"
                                                        max="22:00"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col cols="3" class="pa-0 ml-5" style="height:60px">

                                            <v-menu
                                                    ref="menuDo"
                                                    v-model="menu3[index]"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="getOpenHoursForDay(index).openHoursTimes[0].closeTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            v-model="getOpenHoursForDay(index).openHoursTimes[0].closeTime"
                                                            label="Do"
                                                            prepend-icon="access_time"
                                                            readonly
                                                            v-on="on"
                                                            color="light-green darken-1"
                                                            :disabled="!disabledHoursOnCertainDay[index]"
                                                            @change="madeUnsavedChanges()"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                        v-if="menu3"
                                                        v-model="getOpenHoursForDay(index).openHoursTimes[0].closeTime"
                                                        format="24hr"
                                                        color="light-green darken-1"
                                                        full-width
                                                        @click:minute="$refs.menuDo[index].save(getOpenHoursForDay(index).openHoursTimes[0].closeTime)"
                                                        min="6:00"
                                                        max="22:00"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                    </div>
                                </v-row>

                            </div>
                            <div v-else id="openHoursText">Tohle místo nemá nastavenou otevírací dobu</div>
                        </div>

                        <div id="other-settings" class="pt-6">
                            <h5 class="mt-10 mb-3">
                                Ostatní nastavení
                            </h5>
                            <v-row>
                                <v-col>
                                    <v-checkbox v-model="singleDetail.clickable" class="mx-2 ml-0" color="light-green darken-1" label="Umožnit kliknutí na místo" @change="madeUnsavedChanges()"></v-checkbox>
                                </v-col>
                                <v-col>
                                    <v-checkbox v-model="singleDetail.searchable" class="mx-2 ml-0" color="light-green darken-1" label="Umožnit vyhledání místa" @change="madeUnsavedChanges()"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-btn v-if="!newPlace" text color="error" @click="attemptToDeletePlaceDetail(singleDetail)" class="mt-4">Smazat místo</v-btn>
                        </div>

                        <div id="last-update" v-if="!newPlace">
                            <v-row class="pt-10">
                                <v-col v-if="singleDetail.created !== null">
                                    <i >Vytvořeno: </i>
                                    <span v-if="singleDetail.created">{{ formatDateTime(singleDetail.created) }}</span>
                                </v-col>
                                <v-col>
                                    <i>Naposledy upraveno: </i>
                                    <span v-if="singleDetail.lastUpdate">{{ formatDateTime(singleDetail.lastUpdate) }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="5" id="right-side">
                    <div class="map-component">
                        <h4 class="mb-3 title">
                            Geometrie
                            <v-btn color="primary" text v-on:click="toggleManualDialog()" class="pull-right">Upravit</v-btn>
                        </h4>
                        <div class="map">
                            <MapWidget
                                :places="mapData"
                                :selected="newPlace ? [] : [singleDetail.id]"
                                :center="singleDetail.position"
                            />
                        </div>
                    </div>

                    <geometry-dialog
                      :showDialog="this.showManualDialog"
                      :position="this.singleDetail.position"
                      :showCentroid="false"
                      :placeType="this.singleDetail.type"
                      :placeName="this.singleDetail.localizedAttributes.cz.name"
                      :backgroundObjects="backgroundObjectsExceptEditedOne"
                      @updatePosition="updatePosition"
                      @close="toggleManualDialog()"
                    />

                    <div id="photos-component" class="mt-3">
                        <h4 class="mb-3 title">
                            Fotografie
                        </h4>
                        <template v-if="!newPlace && getPlaceImages.length > 0">
                          <v-card>
                              <v-carousel height="23vw" v-model="carouselActiveImage">
                                  <v-btn v-if="!newPlace" fab dark small color="error" @click="attemptToDeleteActiveImage()" class="ml-5 mb-3 pull-right delete-image">
                                      <v-icon>delete</v-icon>
                                  </v-btn>
                                  <v-carousel-item class="photo-item"
                                          v-for="(image ,i) in getPlaceImages"
                                          :key="i"
                                          :src="getImageUrl(image.id)"
                                          @click="redirect(getImageUrl(image.id))"
                                          reverse-transition="fade-transition"
                                          transition="fade-transition"
                                  ></v-carousel-item>
                              </v-carousel>
                          </v-card>
                        </template>
                        <template>
                          <vue-dropzone
                            ref="dropzone"
                            id="uploader"
                            :options="dropzoneOptions"
                            @vdropzone-complete="finishSaving()"
                            @vdropzone-file-added="imagesUploading = true"
                            @vdropzone-success="onFileSucessfullyUploaded"
                        ></vue-dropzone>
                        </template>
                    </div>
                </v-col>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Vue from 'vue';
    import { config } from '@/config';
    import moment from 'moment';
    import Loading from "../../components/Loading";
    import MapWidget from "@/components/map/MapWidget";
    import BackButton from "../../components/layout/BackButton";
    import ModuleHeader from "../../components/layout/ModuleHeader";
    import AccessSelect from "@/components/map/AccessSelect";
    import { getLocalizedLanguages } from "@/localization/languages";
    import GeometryDialog from '../../components/map/GeometryDialog.vue';
    import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';

    export default {
        name: "PlaceDetail",

        props: {
          id: String,
        },

        components: {
            Loading,
            MapWidget,
            BackButton,
            ModuleHeader,
            AccessSelect,
            GeometryDialog,
            vueDropzone: vue2Dropzone,
            ItemNotFoundState
        },

        data(){
            const allowedFileTypes = ".jpg,.jpeg,.png";

            return {
                dialog:false,
                menu2: [],
                menu3: [],
                hasRecentlyUpdated: false,
                isUpdating: false,
                currentLanguage: "cz",
                showManualDialog: false,
                disabledHoursOnCertainDay:[],
                openHoursAreDisplayed: false,
                processing:false,
                buildingType: "",
                disabledFloor: false,
                carouselActiveImage: 0,
                floorList: [],
                polygonBtnText: "Uložit",
                dropzoneOptions: {
                    url: config.serverUrl + 'map/images/' + this.id,
                    method: 'post',
                    thumbnailWidth: 200,
                    thumbnailHeight: 200,
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 9999, // always generate thumbnails, otherwise we are not able to check minimal dimensions
                    addRemoveLinks: false,
                    acceptedFiles: allowedFileTypes,
                    autoProcessQueue: true,
                    filesizeBase: 1000, // use 1MB=1000KB as the metric for maxFilesize
                    maxFilesize: 29, // MB, must be lower than limit in nginx proxy and lower than limit on backend!
                    paramName: "file",
                    headers: { "Authorization" : "Bearer " + Vue.$auth.getToken() },
                    dictDefaultMessage: "Přetáhněte fotky pro nahrání",
                    dictFileTooBig: "Příliš velký soubor ({{filesize}} MB). Max. {{maxFilesize}} MB.",
                    dictInvalidFileType: "Obrázek musí být ve formátu " + allowedFileTypes,
                    dictReposnseError: "Chyba serveru {{statusCode}}",
                    init: function () {
                      this.on("thumbnail", function(file) {
                          if (file.acceptDimensions && file.rejectDimensions) {
                            if (file.width < 1000 || file.height < 1000 ) {
                                file.rejectDimensions()
                            } else {
                                file.acceptDimensions();
                            }
                          }
                      });
                     },
                    accept: function(file, done) {
                        file.acceptDimensions = done;
                        file.rejectDimensions = function() { done("Obrázek je příliš malý, min. 1000px!"); };
                    }
                },
                imagesUploading: false
            }
        },

        async mounted(){
            this.clear(); //idk why but I have to clear errors here instead of in this.newPlace branch, otherwise objectNotFound is true

            if (!this.newPlace) {
                await this.fetchPlace(this.id) // always refetch the current place
                if (this.objectNotFound)
                    return;
                if (!this.singleDetail.localizedAttributes["en"]) {
                    Vue.set(this.singleDetail.localizedAttributes, 'en', { "name": "","description": "","nickname": "" })
                }
            } else {
                this.createNewPlace();
                this.setDidLoadedPlace(true);
                this.initiateDisabledHours();
            }

            await this.fetchAllBuildings();
            await this.fetchAllFloors();
            await this.setBuildingType();
        },

        computed: {
            ...mapGetters('floors', ['getBuildingFloors', 'getFloorById']),
            ...mapGetters('errors', ['objectNotFound']),
            ...mapGetters('buildings', ['getBuildings', 'getSingleBuilding']),
            ...mapGetters('places', ['getPlace', 'getPlacesTypes', 'getTypeName', 'getDays', 'didLoadedPlace', 'isProcessing', 'getNewPlace', 'getPlaceImages', 'placesByFloor', ]),

            buildingList() {
                let buildings = this.getBuildings.map(building => ({ text: building.localizedAttributes.cz.name, value: building.id }));
                buildings.unshift(({ text: "Exteriér", value: -1 }));
                return buildings;
            },

            singleDetail(){
                return this.newPlace ? this.getNewPlace : this.getPlace;
            },

            // if openHours is empty, openHours div isn't displayed, if it's >0, it is displayed
            openHours: {
                get() {
                    return this.newPlace || !this.getPlace ? [] : this.getPlace.openHours;
                },
                set(){}
            },

            newPlace(){
                return this.id === "new";
            },

            isPositionFilled() {
                return this.singleDetail.position && this.singleDetail.position.length > 0;
            },

            isTypeFilled() {
                return !!this.singleDetail.type;
            },

            isFormInvalid()  {
                return !(
                    this.isTypeFilled &&
                    this.isPositionFilled
                );
            },

            floorId(){
                return this.singleDetail ? this.singleDetail.floorId : null;
            },

            languages() {
              const langs = getLocalizedLanguages();
              const selectArr = [];
              Object.keys(langs).map((lng) => {
                  selectArr.push({
                    'text': langs[lng],
                    'value': lng,
                  });
              });
              return selectArr;
            },

            backgroundObjects() {
                if (this.buildingType !== -1 && this.floorId === null) {
                    return [];
                } else {
                    return this.placesByFloor(this.floorId).map(p => ({
                        id: p.id,
                        type: p.type,
                        position: p.position,
                        name: p.localizedAttributes.cz.name,
                    }));
                 }
             },

            backgroundObjectsExceptEditedOne() {
                return this.backgroundObjects.filter(o => o.id != this.singleDetail.id);
            },

            mapData() {
                return [
                    ...this.backgroundObjectsExceptEditedOne,
                    {
                        id: this.singleDetail.id,
                        type: this.singleDetail.type,
                        position: this.singleDetail.position,
                        name: this.singleDetail.localizedAttributes.cz.nickname || this.singleDetail.localizedAttributes.cz.name
                    }
                ];
            },

            sortedPlaceTypes() {
                let copy = [...this.getPlacesTypes];
                copy.sort((a, b) => a.value.localeCompare(b.value));
                return copy;
            },
        },

        watch: {

            // after place gets first value, disabledHoursOnCertainDay is initialized and hours on days that are missing are filled
            singleDetail(newValue){
                if (newValue) {
                    this.initiateOpenHours(newValue);
                    this.setBuildingType();
                }
            },

            buildingType() {
                this.calculateFloorList();
            },

            // when checking/unchecking checkbox with value openHoursAreDisplayed, days are either filled or it is set empty
            openHoursAreDisplayed(newValue){
                if(newValue) {
                    this.addMissingDays(this.openHours);
                } else {
                    this.openHours = [];
                }
            },

            openHours: {
                    deep: true,
                    handler (newValue) {
                        //sets visibility of openhours
                        this.openHoursAreDisplayed = newValue.length !== 0;
                    }
            },

            disabledFloorComputed(newValue){
                this.disabledFloor = newValue;
            },

            async floorId(newVal) {
                if ( newVal !== null ) {
                    await this.fetchFilteredPlaces({floorId: newVal, buildingId: null, exterior: null, placeName: null, language: null})
                } else {
                    await this.fetchFilteredPlaces({floorId: null, buildingId: null, exterior: true, placeName: null, language: null})
                }
            },

        },

        methods: {
            ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
            ...mapActions('floors', ['fetchFloorById', 'clearFloors', 'fetchAllFloors']),
            ...mapActions('errors', ['clear']),
            ...mapActions('buildings', ['fetchAllBuildings']),
            ...mapActions('places', [ 'updatePlace', 'fetchPlace', 'deletePlace', 'postPlace', 'createNewPlace', 'deleteImage', 'addImage', 'fetchFilteredPlaces']),
            ...mapMutations('places',['setDidLoadedPlace','setProcessing']),

            redirect(src){
                window.open(src, '_blank');
            },

            typeName(value){
                return this.getTypeName(value);
            },

            toggleManualDialog() {
              this.showManualDialog = !this.showManualDialog;
            },

            building() {
                return this.getSingleBuilding(this.buildingType);
            },

            calculateFloorList() {
              if( this.buildingType !== "" && this.buildingType !== -1) {
                  this.disabledFloor = false;
                  const floors = this.getBuildingFloors(this.buildingType);
                  this.floorList = floors.map(floor => ({text: this.getLocalizedFloor(floor), value: floor.id}));
              } else if (this.buildingType == -1) {
                this.disabledFloor = true;
                this.singleDetail.floorId = null;
              } else {
                 this.floorList = [];
              }
            },

            getOpenHoursForDay(index) {
                return this.openHours.find(i => i.day === index + 1);
            },

            getLocalizedFloor(floor) {
              const floorName = floor.localizedAttributes[this.currentLanguage];
              return floorName ? floorName.name : 'Chybí překlad';
            },

            uploadImages( placeId ) {
                if(this.$refs.dropzone.getQueuedFiles().length > 0 ){
                  this.$refs.dropzone.setOption('url', config.serverUrl + 'map/images/' + placeId);
                  this.$refs.dropzone.processQueue();
                } else {
                  this.finishSaving();
                }
            },

            finishSaving() {
              this.didSaveChanges();
              this.imagesUploading = false;
              this.hasRecentlyUpdated = true;
              this.isUpdating = false;
              setTimeout(() => this.hasRecentlyUpdated = false, 2000);
              if(this.newPlace){
                  setTimeout(() => {
                      this.setProcessing(false);
                      this.$router.push({name: "map", params: { tab: "places"}});
                  }, 1500);
              }
            },

            attemptSaveChanges(){
                if ( this.imagesUploading ){
                    alert('Počkejte prosím na nahrání obrázků, poté znova uložte.');
                    return;
                }
                this.saveChanges();
            },

            saveChanges() {
                if(this.$refs.form.validate()){
                    this.isUpdating = true;
                    this.singleDetail.lastUpdate = new Date().toJSON().slice(0, 10).replace(/-/g, '');
                    this.singleDetail.openHours = this.openHours;
                    const updatedItem = this.getPlace;
                    let newOpenHours = this.deleteRecordsFromOpenHours();

                    if(this.newPlace){
                        if(!this.isProcessing) {
                            this.singleDetail.openHours = this.openHoursAreDisplayed ? newOpenHours : [];
                            this.postPlace({place: this.singleDetail}).then((newPlace) => {
                              this.uploadImages(newPlace.id);
                            });
                        }
                    }else {
                        this.updatePlace({
                            id: this.id,
                            item: {
                                localizedAttributes: updatedItem.localizedAttributes,
                                type: updatedItem.type,
                                floorId: updatedItem.floorId,
                                code: updatedItem.code,
                                accessType: updatedItem.accessType,
                                numberOfSeats: updatedItem.numberOfSeats,
                                numberOfImages: this.$refs.dropzone.getQueuedFiles().length,
                                computerWithProjector: updatedItem.computerWithProjector,
                                whiteboard: updatedItem.whiteboard,
                                person1Id: updatedItem.person1Id,
                                person2Id: updatedItem.person2Id,
                                phone: updatedItem.phone,
                                email: updatedItem.email,
                                url: updatedItem.url,
                                searchable: updatedItem.searchable,
                                clickable: updatedItem.clickable,
                                openHours: this.openHoursAreDisplayed ? newOpenHours : [],      //when openhours are displayed, they're saved, otherwise they are saved as empty arr
                                lastUpdate: updatedItem.lastUpdate,
                                position: updatedItem.position,
                            }
                        }).then((updatedPlace) => {
                          this.uploadImages(updatedPlace.id);
                          this.fetchPlace(this.id);
                        });
                    }
                }
            },

            getImageUrl(id){
              return config.serverUrl+"map/images/"+this.singleDetail.id+"/"+ id;
            },

            attemptToDeletePlaceDetail(item) {
                if (confirm("Opravdu smazat tohle místo?")) {
                    this.deletePlace(item.id);
                    setTimeout(() => this.$router.go(-1), 1000);
                }
            },

            formatDateTime(timestamp) {
                return moment(timestamp).format('lll');
            },

            updatePosition(value) {
                this.madeUnsavedChanges();
                this.singleDetail.position = value;
            },

            // fills empty openHours array with default 7 days and default hours
            addMissingDays(hours){
                for (let day = hours.length; day < 7; day++) {
                    this.addToHours(hours, day);
                }
            },

            addToHours(hours, day){
                hours.push({
                    day: day + 1,
                    openHoursTimes: [
                        {
                            closeTime: "22:00",
                            openTime: "07:00"
                        }]
                });
            },

            // filters old openHours and deletes hours, which are not checked as displayed and returns newOpenHours
            deleteRecordsFromOpenHours(){
                let newOpenHours = [];
                this.disabledHoursOnCertainDay.forEach((el, index) => {
                    if (el){
                        newOpenHours.push(this.openHours[index]);
                    }
                });
                return newOpenHours;
            },

            //initializes arr DisabledHoursOnCertainDays with boolean values depending on the data saved in backend
            initiateDisabledHours() {
                if (this.openHours.length === 0 ){
                    for (let i=0; i<7; i++){
                        this.disabledHoursOnCertainDay[i] = true;
                    }
                } else {
                    for (let i=0; i<7; i++){
                        this.disabledHoursOnCertainDay[i] = false;
                    }
                    this.disabledHoursOnCertainDay.forEach((el, index) =>{
                        this.openHours.forEach( hour =>{
                            if(hour.day === index+1){
                                this.disabledHoursOnCertainDay[index] = true;
                            }
                        });
                    });
                }
            },

            //when data from backend are missing some days, it makes sure that they're filled with default values so that they can be displayed
            controlOpenHours(hours){
                let missingDays = [1,2,3,4,5,6,7].filter(x => {
                    let givenDays = [];
                    hours.forEach(el => {
                        givenDays.push(el.day);
                    });
                    return !givenDays.includes(x);
                });

                let j=0;
                for (let i=0; i<7; i++){
                    if (hours[i] === undefined){
                        this.addToHours(hours,missingDays[j]-1);
                        j++;
                    }
                }
                this.sortArray(hours);
            },

            sortArray(array){
                array.sort((a,b) => a.day - b.day);
            },

            initiateOpenHours(value){
                this.initiateDisabledHours();     //array with boolean
                if(value.openHours.length !== 0){
                    this.controlOpenHours(value.openHours);
                }
            },

            // Place has only floor ID, hence, we have to find the parent building via the floor object
            async setBuildingType(){
                const floorId = this.singleDetail.floorId;
                if (Number.isInteger(floorId)){
                    await this.fetchAllFloors().then(() => {
                        const floor = this.getFloorById(floorId);
                        this.buildingType = floor.buildingId;
                    })

                } else if (floorId === null) {
                    this.buildingType = -1;
                }
            },

            attemptToDeleteActiveImage() {
                if (window.confirm('Opravdu chcete smazat tento obrázek natrvalo?')) {
                    this.deleteImage({
                        placeid: this.singleDetail.id,
                        id: this.getPlaceImages[this.carouselActiveImage].id
                    });
                }
            },

            onFileSucessfullyUploaded(file, response) {
                // add the new uploaded image to the carousel
                this.addImage(response.content);
                // after a while, remove the uploaded image from dropzone
                setTimeout(() => {
                    this.$refs.dropzone.removeFile(file);
                }, 2000);
            },
        }
    }
</script>

<style>

    .language-selector {
      height: 80px;
    }

    .language-selector > .v-input{
        width: 140px;
    }

     #smallMap, .leaflet-container, .leaflet-pane {
         z-index: 1 !important;
     }

    #openHoursText {
        color: #757575;
    }

    #photos-component{
        max-width: 30vw;
    }

    .map {
        height: 60vh;
    }

    #right-side{
        padding-left: 3vw;
    }

    .delete-image {
      position: absolute;
      z-index: 9;
      margin-top: 15px;
      right: 15px;
    }

    #uploader {
      padding: 5px;
    }

    #uploader .dz-message {
      margin: 3em 0;
    }

    #uploader .dz-error-message {
      opacity: 1;
      top: 0px;
      border-radius: 0px;
    }

</style>
